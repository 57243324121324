
import AddressUserForm from "./AddressUserDetail.vue";
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  nextTick,
  watch,
} from "vue";
import ShippingAddressDetailForm from "./ShippingAddressDetailForm.vue";
import { GlobalActionTypes } from "@/store/modules/global/action-types";
import BillingAddressDetailForm from "@/components/transaction/status9/component-guest/BillingAddressDetailForm.vue";
import { useStore } from "@/store";
import { ValidationArgs } from "@vuelidate/core";
import AddressListPanel from "../component/AddressListPanel.vue";
import { BuyerInformation } from "@/types/transaction/TransactionAddress.interface";

export default defineComponent({
  components: {
    AddressUserForm,
    ShippingAddressDetailForm,
    BillingAddressDetailForm,
    AddressListPanel,
  },
  props: {
    v: {
      required: true,
      type: Object as PropType<ValidationArgs<BuyerInformation>>,
    },
    sessionId: {
      required: false,
      type: String,
    },
    isSelfPickup: {
      required: true,
      type: Boolean,
    },
  },
  emits: ["onSetSelfPick", "onCalculateShippingCost", "setSelfPickupAddress"],
  setup(props, { emit }) {
    const stroe = useStore();
    const transaction = computed(() => stroe.getters.getTransaction);
    const isSelfPick = ref<boolean>(false);
    const selfPickupAddress = ref<string>("");

    const store = useStore();

    const setSelfPickupAddressDetail = () => {
      emit("setSelfPickupAddress", selfPickupAddress.value);
    };

    // const onGetSelPickFlag = () => {
    //   isSelfPick.value = props.isSelfPickup;
    // };

    const onCalculateShippingCost = () => {
      nextTick(() => {
        store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, true);
      });
      emit("onCalculateShippingCost");
    };

    const setSelfPickupAddress = (value: string) => {
      emit("setSelfPickupAddress", value);
    };

    onMounted(() => {
      const getAddress: any = transaction.value;

      selfPickupAddress.value = getAddress.selfPickingLocation;
      emit("setSelfPickupAddress", selfPickupAddress.value);
    });

    watch(
      () => props.isSelfPickup,
      (val) => {
        isSelfPick.value = val;
      }
    );

    const replaceAddress = () => {
      transaction.value.buyerInfo.sameAddress = false;

      let {
        id,
        address,
        road,
        province,
        district,
        subdistrict,
        zipcode,
        tax,
        nameTitleId,
        zipcodeExternalId,
        shippingNote,
      } = { ...transaction.value.buyerInfo.shippingAddress };

      let { firstname, lastname } = {
        ...transaction.value.buyerInfo.userInfo,
      };

      transaction.value.buyerInfo.billingAddress.id = id;
      transaction.value.buyerInfo.billingAddress.firstname = firstname;
      transaction.value.buyerInfo.billingAddress.lastname = lastname;
      transaction.value.buyerInfo.billingAddress.address = address;
      transaction.value.buyerInfo.billingAddress.road = road;
      transaction.value.buyerInfo.billingAddress.province = province;
      transaction.value.buyerInfo.billingAddress.district = district;
      transaction.value.buyerInfo.billingAddress.subdistrict = subdistrict;
      transaction.value.buyerInfo.billingAddress.zipcode = zipcode;
      transaction.value.buyerInfo.billingAddress.tax = tax;
      transaction.value.buyerInfo.billingAddress.nameTitleId = nameTitleId;
      transaction.value.buyerInfo.billingAddress.zipcodeExternalId =
        zipcodeExternalId;
      transaction.value.buyerInfo.billingAddress.shippingNote = shippingNote;
    };

    return {
      transaction,
      isSelfPick,
      selfPickupAddress,
      onCalculateShippingCost,
      setSelfPickupAddress,
      setSelfPickupAddressDetail,
      replaceAddress,
    };
  },
});
