
import TransactionService from "@/service/transaction-service/TransactionService";
import { Bank } from "@/types/master/Bank.interface";
import { defineComponent, ref, computed, onMounted } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  setup() {
    const store = useStore();
    const transaction = computed(() => store.getters.getTransaction);
    const transactionService = new TransactionService();
    const bankList = ref<Bank[]>([]);
    const isCollapsed = ref<boolean>(false);
    const isCopySuccess = ref<boolean>(false);

    const getBankList = () => {
      transactionService
        .getBankList(transaction.value.id)
        .then((data: Bank[]) => {
          bankList.value = data;
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    onMounted(() => {
      getBankList();
    });

    const copyTestingCode = (text: string) => {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          isCopySuccess.value = true;

          setInterval(() => {
            isCopySuccess.value = false;
          }, 5000);
        })
        .catch((err: any) => {
          console.log("Error in copying text: ", err);
        });
    };

    return { isCollapsed, bankList, copyTestingCode, isCopySuccess };
  },
});
