
import { NameTitle } from "@/types/master/NameTitle.interface";
import {
  Address,
  AddressInformation,
} from "@/types/transaction/TransactionAddress.interface";
import { computed, defineComponent, onMounted, PropType, ref } from "vue";
import InputTextCustom from "@/components/inputs/InputText.vue";
import { useStore } from "@/store";
import TransactionService from "@/service/transaction-service/TransactionService";
import InputSelect from "@/components/inputs/InputSelect.vue";
import InputAutoComplete from "@/components/inputs/InputAutoComplete.vue";

export default defineComponent({
  components: {
    InputTextCustom,
    InputSelect,
    InputAutoComplete,
  },
  props: {
    v: {
      required: true,
      type: Object,
    },
    isRequestTaxInvoice: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    const nameTitleArray = ref<NameTitle[]>([]);
    const store = useStore();
    const form = ref<Address>({} as Address);
    const isNumber = (evt: any) => {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    };
    const transaction = computed(() => store.getters.getTransaction);
    const transactionService = new TransactionService();
    const filteredProvince = ref<AddressInformation[]>([]);
    const nameTitleList = ref<NameTitle[]>([
      { id: 0, name: "กรุณากรอกคำนำหน้าชื่อ" },
    ]);
    form.value = computed(
      () => transaction.value.buyerInfo.billingAddress
    ).value;
    const getNameTitleList = async () => {
      await transactionService
        .getNameTitleList()
        .then((nameTitle: NameTitle[]) => {
          nameTitleList.value = nameTitleList.value.concat(nameTitle);
        });
    };

    const onClearItem = (event: any) => {
      if (form.value) {
        form.value.district = "";
        form.value.province = "";
        form.value.zipcode = "";
        form.value.zipcodeExternalId = 0;
      }
    };

    const onSelectItem = (event: any) => {
      if (form.value) {
        form.value.district = event.value?.districtInfo.districtNameLocal;
        form.value.province = event.value?.provinceInfo.provinceNameLocal;
        form.value.zipcode = event.value?.zipcode;
        form.value.zipcodeExternalId = event.value?.id;
      }
    };

    const searchProvince = (event: any) => {
      if (form.value) {
        form.value.zipcode = form.value?.zipcode.replace(/[^0-9]+/g, "");
        if (form.value.zipcode.length > 1) {
          transactionService
            .getAddressDropdownList(form.value?.zipcode)
            .then((res: AddressInformation[]) => {
              filteredProvince.value = res;
            })
            .catch((error) => {
              // console.log(error);
            });
        }
      }
    };

    const onNameTitleChange = (event: any) => {
      transaction.value.buyerInfo.billingAddress.nameTitleId = event.value;
    };

    const handleAddress = () => {
      // console.log("handleAddress");
    };

    onMounted(() => getNameTitleList());

    const handlerZipcode = (event: any) => {
      if (!form.value) return;
      form.value.zipcode = event.replace(/[^0-9]+/g, "");
    };

    const titleFirstname = computed(() =>
      form.value?.nameTitleId == 4 ? "ชื่อบริษัท" : "ชื่อ"
    );
    const titleLastname = computed(() =>
      form.value?.nameTitleId == 4 ? "สาขา" : "นามสกุล"
    );

    return {
      titleFirstname,
      titleLastname,
      isNumber,
      nameTitleArray,
      form,
      nameTitleList,
      handleAddress,
      onNameTitleChange,
      filteredProvince,
      searchProvince,
      onSelectItem,
      onClearItem,
      handlerZipcode,
    };
  },
});
