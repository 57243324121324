import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d57d29b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "div-input" }
const _hoisted_2 = {
  key: 0,
  class: "display-only"
}
const _hoisted_3 = {
  key: 0,
  class: "p-error"
}
const _hoisted_4 = ["type", "placeholder", "name", "inputmode", "oninput", "required", "value", "size", "maxlength", "disabled", "autocomplete"]
const _hoisted_5 = {
  key: 1,
  class: "text-desc"
}
const _hoisted_6 = { key: 2 }
const _hoisted_7 = {
  key: 0,
  class: "text-error"
}
const _hoisted_8 = {
  key: 1,
  class: "text-error"
}
const _hoisted_9 = {
  key: 2,
  class: "text-error"
}
const _hoisted_10 = {
  key: 3,
  class: "text-error"
}
const _hoisted_11 = {
  key: 4,
  class: "text-error"
}
const _hoisted_12 = {
  key: 5,
  class: "text-error"
}
const _hoisted_13 = {
  key: 6,
  class: "text-error"
}
const _hoisted_14 = {
  key: 7,
  class: "text-error"
}
const _hoisted_15 = {
  key: 8,
  class: "text-error"
}
const _hoisted_16 = {
  key: 9,
  class: "text-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isDisplay)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(['input-custom', { error: _ctx.isValidate }])
      }, [
        _createElementVNode("label", {
          class: _normalizeClass(_ctx.className)
        }, [
          _createTextVNode(_toDisplayString(_ctx.textFloat) + " ", 1),
          (_ctx.isRequired)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("input", {
          class: _normalizeClass(['custom-input f-regular ']),
          type: _ctx.type,
          placeholder: _ctx.placeholder,
          name: _ctx.name,
          inputmode: _ctx.inputmode,
          oninput: _ctx.oninput,
          required: _ctx.required,
          value: _ctx.modelValue,
          size: _ctx.size,
          onInput: _cache[0] || (_cache[0] = (event) => _ctx.$emit('update:modelValue', event.target.value)),
          ref: "input",
          maxlength: _ctx.maxlength,
          disabled: _ctx.isDisplay,
          autocomplete: 'new-evnet' + Math.floor(Date.now() / 1000)
        }, null, 40, _hoisted_4)
      ], 2),
      (_ctx.detail)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.detail), 1))
        : _createCommentVNode("", true),
      (_ctx.v && _ctx.v.$error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_ctx.v.required && _ctx.v.required.$invalid == true)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, " กรุณากรอกข้อมูลให้ครบถ้วน "))
              : (_ctx.v.minLength && _ctx.v.minLength.$invalid == true)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                    _createElementVNode("span", null, " กรุณากรอกอย่างน้อย " + _toDisplayString(_ctx.v.minLength.$params.min) + " อักษร ", 1)
                  ]))
                : (_ctx.v.validateTotal && _ctx.v.validateTotal.$invalid == true)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, "กรุณาระบุจำนวนเงินให้ถูกต้อง"))
                  : (_ctx.v.email && _ctx.v.email.$invalid == true)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_10, "กรุณากรอกอีเมล (เช่น email@example.com)\","))
                    : (_ctx.v.integer && _ctx.v.integer.$invalid == true)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_11, "กรุณากรอกตัวเลขเท่านั้น"))
                      : (_ctx.v.maxLength && _ctx.v.maxLength.$invalid == true)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_12, "กรุณากรอกไม่เกิน " + _toDisplayString(_ctx.v.$params.maxLength.max) + " อักษร", 1))
                        : (_ctx.v.decimal && _ctx.v.decimal.$invalid == true)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_13, "กรุณากรอกตัวเลขเท่านั้น"))
                          : (_ctx.v.minValue && _ctx.v.minValue.$invalid == true)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_14, "กรุณากรอกตัวเลขเท่านั้น"))
                            : (_ctx.v.alpha && _ctx.v.alpha.$invalid == true)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_15, "กรอกเฉพาะตัวอักษรเท่านั้น"))
                              : (_ctx.v.address && _ctx.v.address.$invalid == true)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_16, "กรุณากรอกข้อมูลที่อยู่ให้ถูกต้อง"))
                                : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}