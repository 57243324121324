
import TransactionService from "@/service/transaction-service/TransactionService";
import ShippingAddressDetailForm2 from "@/components/transaction/status9/component-guest/ShippingAddressDetailForm2.vue";
import BillingAddressDetailForm2 from "@/components/transaction/status9/component-guest/BillingAddressDetailForm2.vue";
import { useStore } from "@/store";
import { NameTitle } from "@/types/master/NameTitle.interface";
import {
  Address,
  BuyerInformation,
} from "@/types/transaction/TransactionAddress.interface";
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  PropType,
  ref,
} from "vue";
import InputTextCustom from "@/components/inputs/InputText.vue";
import InputSelect from "@/components/inputs/InputSelect.vue";
import { ValidationArgs } from "@vuelidate/core";
import ModalAlertError from "@/components/modal/ModalAlertError.vue";
import { GlobalActionTypes } from "@/store/modules/global/action-types";
import { DefaultResponse } from "@/types/master/DefaultResponse.interface";
// import ModalAddAddress from "./ModalAddAddress.vue";
import ModalAddAdress2 from "./ModalAddAdress2.vue";
import ModalAddAddressBliing from "./ModalAddAddressBliing.vue";
import {
  TransactionShippingCost,
  TransactionShippingCostResponse,
} from "@/types/transaction/Transaction.interface";

export default defineComponent({
  props: {
    sessionId: {
      required: true,
      type: String,
    },
    v: {
      required: true,
      type: Object as PropType<ValidationArgs<BuyerInformation>>,
    },
    isSelfPick: {
      required: true,
      type: Boolean,
    },
  },
  components: {
    InputTextCustom,
    InputSelect,
    ModalAlertError,
    // ModalAddAddress,
    ShippingAddressDetailForm2,
    ModalAddAdress2,
    BillingAddressDetailForm2,
    ModalAddAddressBliing,
  },
  emits: ["onCalculateShippingCost", "setSelfPickupAddress"],
  setup(props, { emit }) {
    const transactionService = new TransactionService();
    const addressList = ref<Address[]>([]);
    const nameTitleList = ref<NameTitle[]>([
      { id: 0, name: "กรุณากรอกคำนำหน้าชื่อ" },
    ]);
    const transaction = computed(() => store.getters.getTransaction);
    const modalAlertError = ref<InstanceType<typeof ModalAlertError>>();
    const modalAddAdress2 = ref<InstanceType<typeof ModalAddAdress2>>();
    const modalAddAddressBilling =
      ref<InstanceType<typeof ModalAddAddressBliing>>();
    // const modalAddAddress = ref<InstanceType<typeof ModalAddAddress>>();
    const modalMessage = ref<string>("");
    const addressSelected = ref<Address>();
    const forceRefresh = ref<boolean>(true);
    const shippingCost = ref<number>(0);
    const selfPickupAddress = ref<string>("");
    const changeTransaction = ref<boolean>(true);
    const renderModal = ref<boolean>(false);

    const store = useStore();

    const setSelfPickupAddress = () => {
      emit("setSelfPickupAddress", selfPickupAddress.value);
    };

    const getAddressList = async () => {
      await transactionService
        .getAddressList(props.sessionId)
        .then((address: Address[]) => {
          addressList.value = address;
          console.log(address);
          if (transaction.value.buyerInfo.shippingAddressId == 0) {
            handleSelectAddress(address[0]);
          } else {
            let addressSelected = address.find(
              (el: Address) =>
                el.id == transaction.value.buyerInfo.shippingAddressId
            );
            if (addressSelected) {
              handleSelectAddress(addressSelected);
            }
          }
          if (
            !addressList.value.some(
              (el: Address) =>
                el.id == transaction.value.buyerInfo.shippingAddressId
            )
          ) {
            handleSelectAddress(address[0]);
          }
          if (
            !addressList.value.some(
              (el: Address) =>
                el.id == transaction.value.buyerInfo.billingAddressId
            )
          ) {
            handleSelectBillingAddress(address[0]);
          }
        });
    };

    const getNameTitleList = async () => {
      await transactionService
        .getNameTitleList()
        .then((nameTitle: NameTitle[]) => {
          nameTitleList.value = nameTitleList.value.concat(nameTitle);
        });
    };

    const onNameTitleChange = (event: any) => {
      transaction.value.buyerInfo.billingAddress.nameTitleId = event.value;
    };
    const onSetAddressSelected = (address?: Address) => {
      if (address) {
        addressSelected.value = address;
      } else {
        addressSelected.value = {
          address: "",
          district: "",
          firstname: "",
          lastname: "",
          province: "",
          road: "",
          subdistrict: "",
          zipcode: "",
          id: 0,
        } as Address;
      }
    };

    const openModalAddAddress = async (address?: Address) => {
      renderModal.value = await true;
      await onSetAddressSelected(address);
      nextTick(() => {
        modalAddAdress2.value?.openModal();
      });
    };

    const openModalAddAddressBilling = async (address?: Address) => {
      
      renderModal.value = await true;
      await onSetAddressSelected(address);
      nextTick(() => {
        modalAddAddressBilling.value?.openModal();
      });
    };

    const handleSelectAddress = (address: Address) => {
      changeTransaction.value = false;
      console.log("address", address);
      if (address) {
        transaction.value.buyerInfo.shippingAddressId = address.id;
        transaction.value.buyerInfo.shippingAddress = JSON.parse(
          JSON.stringify(address)
        );
        if (
          transaction.value.buyerInfo.sameAddress &&
          !transaction.value.buyerInfo.isRequestTax
        ) {
          transaction.value.buyerInfo.billingAddressId = address.id;
          transaction.value.buyerInfo.billingAddress = JSON.parse(
            JSON.stringify(address)
          );
        } else if (transaction.value.buyerInfo.billingAddressId == 0) {
          transaction.value.buyerInfo.billingAddressId = address.id;
          transaction.value.buyerInfo.billingAddress = JSON.parse(
            JSON.stringify(address)
          );
        }
        // nextTick(() => {
        //   modalAddAdress2.value?.closeModal();
        // });
      }
      onCalculateShippingCost();
    };

    const onCalculateShippingCost = () => {
      changeTransaction.value = true;
      nextTick(() => {
        store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, true);
      });
      emit("onCalculateShippingCost");
    };

    const handleSelectBillingAddress = (address: Address) => {
      if (address) {
        transaction.value.buyerInfo.billingAddressId = address.id;
        transaction.value.buyerInfo.billingAddress = JSON.parse(
          JSON.stringify(address)
        );
        // nextTick(() => {
        //   modalAddAddressBilling.value?.closeModal();
        // });
      }
    };

    const handleSameAddressChange = () => {
      if (!transaction.value.buyerInfo.sameAddress) {
        transaction.value.buyerInfo.billingAddressId = addressList.value[0].id;
        transaction.value.buyerInfo.billingAddress = JSON.parse(
          JSON.stringify(addressList.value[0])
        );
      } else if (!transaction.value.buyerInfo.isRequestTax) {
        transaction.value.buyerInfo.billingAddressId =
          transaction.value.buyerInfo.shippingAddressId;
        transaction.value.buyerInfo.billingAddress = JSON.parse(
          JSON.stringify(transaction.value.buyerInfo.shippingAddress)
        );
      }
    };

    const onDeleteAddress = async (address: Address) => {
      store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, true);
      await transactionService
        .deleteAddress(address, props.sessionId)
        .then(async (res: DefaultResponse) => {
          await getAddressList();
        })
        .catch((error) => {
          store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, false);
          if (error.message) {
            modalMessage.value = error.message;
          } else {
            modalMessage.value = error;
          }
          modalAlertError.value?.setShowModalError(true);
        });
    };

    const onHideModalError = () => {
      console.log("onHideModalError");
    };

    onMounted(async () => {
      await getAddressList();
      await getNameTitleList();
      onSetAddressSelected();
      if (
        transaction.value.shippingTypeId == 7 &&
        transaction.value.SelfPickingLocation
      ) {
        selfPickupAddress.value = transaction.value.SelfPickingLocation;
      }
    });

    let titleFirstname = computed(() => transaction.value.buyerInfo.billingAddress.nameTitleId == 4 ? 'ชื่อบริษัท' : 'ชื่อ');
    let titleLastname = computed(() => transaction.value.buyerInfo.billingAddress.nameTitleId == 4 ? 'สาขา' : 'นามสกุล');

    return {
      titleFirstname,
      titleLastname,
      addressList,
      nameTitleList,
      transaction,
      // modalAlertError,
      modalMessage,
      modalAddAdress2,
      addressSelected,
      onNameTitleChange,
      handleSelectAddress,
      handleSameAddressChange,
      handleSelectBillingAddress,
      onDeleteAddress,
      openModalAddAddress,
      onSetAddressSelected,
      onHideModalError,
      getAddressList,
      shippingCost,
      selfPickupAddress,
      setSelfPickupAddress,
      openModalAddAddressBilling,
      modalAddAddressBilling,
      changeTransaction,
      renderModal,
    };
  },
});
