import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-grid" }
const _hoisted_2 = { class: "p-col-12 p-md-12" }
const _hoisted_3 = { class: "p-col-12 p-md-6" }
const _hoisted_4 = { class: "p-col-12 p-md-6" }
const _hoisted_5 = { class: "p-col-12 p-md-6" }
const _hoisted_6 = { class: "p-col-12 p-md-6" }
const _hoisted_7 = { class: "p-col-12 p-md-6" }
const _hoisted_8 = { class: "p-col-12 p-md-6 p-lg-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputTextCustom = _resolveComponent("InputTextCustom")!
  const _component_InputAutoComplete = _resolveComponent("InputAutoComplete")!
  const _component_InputTextArea = _resolveComponent("InputTextArea")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_InputTextCustom, {
          "model-value": _ctx.form.address,
          textFloat: "ที่อยู่",
          placeholder: "ที่อยู่",
          type: "text",
          name: "address",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.form.address = $event)),
          isRequired: "",
          isValidate: _ctx.v.address.$error,
          v: _ctx.v.address,
          class: "f-regular"
        }, null, 8, ["model-value", "isValidate", "v"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_InputTextCustom, {
          "model-value": _ctx.form.road,
          textFloat: "ถนน",
          placeholder: "ถนน",
          type: "text",
          name: "road",
          v: _ctx.v.road,
          class: "f-regular",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.form.road = $event))
        }, null, 8, ["model-value", "v"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_InputAutoComplete, {
          "model-value": _ctx.form.zipcode,
          placeholder: "รหัสไปรษณีย์",
          title: "รหัสไปรษณีย์",
          text: "รหัสไปรษณีย์",
          name: "zipcode",
          type: "number",
          oninput: "if( this.value.length > 5 )  this.value = this.value.slice(0,5)",
          isRequired: "",
          v: _ctx.v.zipcode,
          isValidate: _ctx.v.zipcode.$error,
          field: 'zipcode',
          options: _ctx.filteredProvince,
          isAddress: true,
          isDisplay: Boolean(_ctx.form.province.length),
          "onUpdate:modelValue": _ctx.handlerZipcode,
          onOnSearchList: _ctx.searchProvince,
          onOnSelectItem: _ctx.onSelectItem,
          onOnClearItem: _ctx.onClearItem
        }, null, 8, ["model-value", "v", "isValidate", "options", "isDisplay", "onUpdate:modelValue", "onOnSearchList", "onOnSelectItem", "onOnClearItem"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_InputTextCustom, {
          "model-value": _ctx.form.province,
          textFloat: "จังหวัด",
          placeholder: "จังหวัด",
          type: "text",
          name: "road",
          v: _ctx.v.province,
          isRequired: "",
          isValidate: _ctx.v.province.$error,
          isDisplay: true,
          class: "f-regular",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.form.province = $event))
        }, null, 8, ["model-value", "v", "isValidate"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_InputTextCustom, {
          "model-value": _ctx.form.district,
          textFloat: "อำเภอ",
          placeholder: "อำเภอ",
          type: "text",
          name: "road",
          v: _ctx.v.district,
          isRequired: "",
          isValidate: _ctx.v.district.$error,
          isDisplay: true,
          class: "f-regular",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.form.district = $event))
        }, null, 8, ["model-value", "v", "isValidate"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_InputTextCustom, {
          "model-value": _ctx.form.subdistrict,
          textFloat: "ตำบล/แขวง",
          placeholder: "ตำบล/แขวง",
          type: "text",
          name: "road",
          v: _ctx.v.subdistrict,
          isRequired: "",
          isValidate: _ctx.v.subdistrict.$error,
          class: "f-regular",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.form.subdistrict = $event))
        }, null, 8, ["model-value", "v", "isValidate"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_InputTextArea, {
          "model-value": _ctx.form.shippingNote,
          textFloat: "บันทึก",
          placeholder: "บันทึก",
          type: "text",
          name: "note",
          rows: "4",
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.form.shippingNote = $event)),
          class: "f-regular"
        }, null, 8, ["model-value"])
      ])
    ])
  ]))
}