
import { UserInformation } from "@/types/transaction/TransactionAddress.interface";
import { ValidationArgs } from "@vuelidate/core";
import { computed, defineComponent, PropType, ref, nextTick, } from "vue";
import InputTextCustom from "@/components/inputs/InputText.vue";
import { GlobalActionTypes } from "@/store/modules/global/action-types";
import { useStore } from "@/store";

export default defineComponent({
  components: {
    InputTextCustom,
  },
  props: {
    v: {
      required: true,
      type: Object as PropType<ValidationArgs>,
    },
  },
  emits: ["onCalculateShippingCost"],
  setup(props, { emit }) {
    const form = ref<UserInformation>();
    const store = useStore();
    let timer = ref<any>(0);

    const isNumber = (evt: any) => {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    };
    const getUserInfoByTelephone = () => {
      // console.log("getUserInfoByTelephone");
    };

    form.value = computed(
      () => store.getters.getTransaction.buyerInfo.userInfo
    ).value;

    const handlerZipcode = (event: any) => {
      if (!form.value) return;
      form.value.telephone = event.replace(/[^0-9]+/g, "");
    };

    const onCalculateShippingCostUserInfo = () => {
      clearTimeout(timer.value);
      timer.value = setTimeout(function validate() {
        if(form.value && form.value.telephone && form.value.firstname && form.value.lastname) {
          emit("onCalculateShippingCost");
          nextTick(() => {
            store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, true);
          });
        } 
      },2000);
    };

    return {
      // useTheoneCard: computed(() => store.getters.getBrand.useThe1Card),
      isNumber,
      getUserInfoByTelephone,
      form,
      handlerZipcode,
      onCalculateShippingCostUserInfo
    };
  },
  watch: {
    "form.telephone": function (newVal, oldVal) {
      if(newVal !== oldVal) this.onCalculateShippingCostUserInfo();
    },
    "form.firstname": function (newVal, oldVal) {
      if(newVal !== oldVal) this.onCalculateShippingCostUserInfo();
    },
    "form.lastname": function (newVal, oldVal) {
      if(newVal !== oldVal) this.onCalculateShippingCostUserInfo();
    },
  },
});
