import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03c2205c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-mt-3" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "p-d-inline-block" }
const _hoisted_4 = { class: "pl-2" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "f-size-14" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Panel, {
      header: "บัญชีธนาคาร",
      toggleable: true,
      collapsed: _ctx.isCollapsed,
      "onUpdate:collapsed": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isCollapsed) = $event))
    }, {
      icons: _withCtx(() => [
        _createElementVNode("span", {
          class: "text-gray-1 f-size-14",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isCollapsed = !_ctx.isCollapsed))
        }, "ดูรายละเอียด")
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bankList, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index + 'bankRadio',
              class: "bank-space"
            }, [
              _createElementVNode("img", {
                class: "p-mx-2 p-align-baseline",
                src: item.imageUrl,
                style: {"width":"30px","height":"30px"}
              }, null, 8, _hoisted_2),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", null, [
                  _createTextVNode(_toDisplayString(item.bankName) + " (" + _toDisplayString(item.accountNo) + " ", 1),
                  _createElementVNode("span", _hoisted_4, [
                    (_ctx.isCopySuccess)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, "คัดลอกสำเร็จ"))
                      : (_openBlock(), _createElementBlock("i", {
                          key: 1,
                          class: "pi pi-copy pointer",
                          onClick: ($event: any) => (_ctx.copyTestingCode(item.accountNo)),
                          style: {"color":"#0b0b89"}
                        }, null, 8, _hoisted_6))
                  ]),
                  _createTextVNode(") ")
                ]),
                _createElementVNode("div", _hoisted_7, _toDisplayString(item.accountName), 1)
              ])
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["collapsed"])
  ]))
}