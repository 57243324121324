
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  watch,
} from "vue";
import TransactionHeader from "@/components/transaction/transaction-detail/header/TransactionHeader.vue";
import TransactionCartDetail from "@/components/transaction/transaction-detail/cart/TransactionCartDetail.vue";
import AddressDetailPanel from "./component-guest/AddressDetailPanel.vue";
import useVuelidate from "@vuelidate/core";
import TransactionService from "@/service/transaction-service/TransactionService";
import { DefaultResponse } from "@/types/master/DefaultResponse.interface";
import { StatusChangeResponse } from "@/types/master/StatusChange.interface";
import ModalAlertError from "@/components/modal/ModalAlertError.vue";

import {
  required,
  numeric,
  minValue,
  helpers,
  email,
  minLength,
  requiredIf,
} from "@vuelidate/validators";
import { useStore } from "@/store";
import { TransactionActionTypes } from "@/store/modules/transaction/action-types";
import { GlobalActionTypes } from "@/store/modules/global/action-types";
import {
  Address,
  AddressGuest,
  BuyerInformation,
} from "@/types/transaction/TransactionAddress.interface";
import { useRoute } from "vue-router";
import { TransactionShippingCostResponse } from "@/types/transaction/Transaction.interface";
import { useCookies } from "vue3-cookies";

const alpha = (value: string) => {
  if (value.match(/^[ก-๙a-zA-Z]*$/)) {
    return true;
  } else {
    return false;
  }
};
const address = (value: string) => {
  if (value.match(/^[ก-๙A-Za-z0-9'\\.\-\s\\,\\/]*$/)) {
    return true;
  } else {
    return false;
  }
};
const valueDefault = (value: number, flag: boolean) => {
  if (value == 0 && flag) {
    return false;
  } else {
    return true;
  }
};

export default defineComponent({
  components: {
    TransactionHeader,
    TransactionCartDetail,
    AddressDetailPanel,
    // AddressListPanel,
    ModalAlertError,
  },
  props: {},
  setup(props) {
    const isCollapsed = ref<boolean>(false);
    const store = useStore();
    const transaction = computed(() => store.getters.getTransaction);
    const transactionService = new TransactionService();
    const v$ = useVuelidate();
    const forceRefresh = ref<boolean>(true);
    const route = useRoute();
    const sessionId = route.query.sessionId?.toString();
    const transactionCartDetailPanel =
      ref<InstanceType<typeof TransactionCartDetail>>();
    const isSelfPickup = ref<boolean>(false);
    const addressDetailPanel = ref<InstanceType<typeof AddressDetailPanel>>();
    const selfPickupAddress = ref<string>("");
    const shippingCost = ref<number>(0);
    const { cookies } = useCookies();
    const modalAlertError = ref<InstanceType<typeof ModalAlertError>>();
    const modalMessage = ref<string>("");
    const onSetSelfPickAddress = (value: string) => {
      selfPickupAddress.value = value;
    };

    const onSetSelfPick = (value: boolean) => {
      isSelfPickup.value = value;

      if (value) {
        transaction.value.buyerInfo.sameAddress = false;

        // clear value
        transaction.value.buyerInfo.shippingAddress.firstname = "";
        transaction.value.buyerInfo.shippingAddress.lastname = "";
        transaction.value.buyerInfo.shippingAddress.address = "";
        transaction.value.buyerInfo.shippingAddress.road = "";
        transaction.value.buyerInfo.shippingAddress.province = "";
        transaction.value.buyerInfo.shippingAddress.district = "";
        transaction.value.buyerInfo.shippingAddress.subdistrict = "";
        transaction.value.buyerInfo.shippingAddress.zipcode = "";
        transaction.value.buyerInfo.shippingAddress.tax = "";
        transaction.value.buyerInfo.shippingAddress.nameTitleId = 0;
        transaction.value.buyerInfo.shippingAddress.id = 0;
        transaction.value.buyerInfo.shippingAddress.shippingNote = "";
      }
    };

    const refreshData = () => {
      transactionCartDetailPanel.value?.refreshData();
    };

    const onCalculateShippingCost = () => {
      let buyerInfo = transaction.value.buyerInfo;
      if (
        !buyerInfo.userInfo.telephone ||
        !buyerInfo.userInfo.firstname ||
        !buyerInfo.userInfo.lastname
      ) {
        nextTick(() => {
          store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, false);
        });
      } else {
        transactionService
          .onCalculateShippingCost(transaction.value.id.toString(), buyerInfo)
          .then((response: TransactionShippingCostResponse) => {
            shippingCost.value = response.detail;
            store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, false);
            if (shippingCost.value === -1) {
              modalMessage.value =
                "ไม่สามารถคำนวณค่าจัดส่งได้ กรุณาลองใหม่อีกครั้ง";
              modalAlertError.value?.setShowModalError(true);
            }
          });
      }
    };

    const handleAddress = (buyerInfo: BuyerInformation) => {
      transaction.value.buyerInfo.shippingAddress.firstname = JSON.parse(
        JSON.stringify(buyerInfo.userInfo.firstname)
      );
      transaction.value.buyerInfo.shippingAddress.lastname = JSON.parse(
        JSON.stringify(buyerInfo.userInfo.lastname)
      );
    };

    const setSelfPickupAddress = (value: string) => {
      selfPickupAddress.value = value;
    };

    const onBeforeSave = () => {
      handleAddress(transaction.value.buyerInfo);
      v$.value.transaction.$touch();
      if (v$.value.transaction.$error) {
        return;
      }

      if (!selfPickupAddress.value && isSelfPickup.value == true) {
        return;
      }

      store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, true);
      transaction.value.buyerInfo.isConsent = true;
      if (store.getters.getPlatform == 2) {
        transaction.value.buyerInfo.userInfo.socialMediaId = 2;
        transaction.value.buyerInfo.userInfo.socialMediaName = "Facebook";
        transaction.value.buyerInfo.userInfo.profilePictureUrl =
          store.getters.getUser.profilePictureUrl;
        transaction.value.buyerInfo.userInfo.socialName =
          store.getters.getUser.socialName;
      } else {
        transaction.value.buyerInfo.userInfo.socialMediaId = 1;
        transaction.value.buyerInfo.userInfo.socialMediaName = "Line";
        transaction.value.buyerInfo.userInfo.profilePictureUrl =
          store.getters.getUser.profilePictureUrl;
        transaction.value.buyerInfo.userInfo.socialName =
          store.getters.getUser.socialName;
      }

      // add adress
      if (isSelfPickup.value) {
        transaction.value.buyerInfo.shippingAddress.address =
          selfPickupAddress.value;
      }

      onSaveBuyerInformation();
    };

    const onSaveBuyerInformation = () => {
      transactionService
        .onSaveBuyerInfo(
          transaction.value.buyerInfo,
          transaction.value.id.toString()
        )
        .then(async (res: DefaultResponse) => {
          if (res.result == 1) {
            if (isSelfPickup.value == true) {
              transactionService.saveSelfpickAddress(
                selfPickupAddress.value,
                transaction.value.id.toString()
              );
            }
            transactionService
              .onChangeStatus(transaction.value.id, 2)
              .then(async (data: StatusChangeResponse) => {
                await store.dispatch(
                  TransactionActionTypes.SET_TRANSACTION,
                  transaction.value.id.toString()
                );
                saveGuestAddres();
              });
          }
        });
    };

    const saveGuestAddres = () => {
      if (store.getters.getPlatform == 0) {
        let cookieAddress = JSON.parse(
          cookies.get("tnl-address-list")
        ) as AddressGuest[];
        if (cookieAddress && cookieAddress.length > 0) {
          let index = cookieAddress.findIndex(
            (el) => el.brandId == transaction.value.brandId
          );

          if (index > -1) {
            cookieAddress[index].buyerInfo = transaction.value.buyerInfo;
            cookies.set("tnl-address-list", JSON.stringify(cookieAddress));
          } else {
            let buyerInfo = {
              brandId: transaction.value.brandId,
              buyerInfo: transaction.value.buyerInfo,
            };
            cookieAddress.push(buyerInfo);
            cookies.set("tnl-address-list", JSON.stringify(cookieAddress));
          }
        } else {
          cookieAddress = [
            {
              brandId: store.getters.getBrand.brandId,
              buyerInfo: transaction.value.buyerInfo,
            },
          ];
          cookies.set("tnl-address-list", JSON.stringify(cookieAddress));
        }
      }
    };

    onMounted(async () => {
      if (transaction.value.shippingTypeId == 7) {
        isSelfPickup.value = true;

        // clear value
        transaction.value.buyerInfo.shippingAddress.firstname = "";
        transaction.value.buyerInfo.shippingAddress.lastname = "";
        transaction.value.buyerInfo.shippingAddress.address = "";
        transaction.value.buyerInfo.shippingAddress.road = "";
        transaction.value.buyerInfo.shippingAddress.province = "";
        transaction.value.buyerInfo.shippingAddress.district = "";
        transaction.value.buyerInfo.shippingAddress.subdistrict = "";
        transaction.value.buyerInfo.shippingAddress.zipcode = "";
        transaction.value.buyerInfo.shippingAddress.tax = "";
        transaction.value.buyerInfo.shippingAddress.nameTitleId = 0;
        transaction.value.buyerInfo.shippingAddress.id = 0;
        transaction.value.buyerInfo.shippingAddress.shippingNote = "";
      }
      refreshData();
    });

    return {
      isCollapsed,
      onBeforeSave,
      v$,
      transaction,
      forceRefresh,
      sessionId,
      isSelfPickup,
      transactionCartDetailPanel,
      addressDetailPanel,
      selfPickupAddress,
      shippingCost,
      onSetSelfPickAddress,
      onSetSelfPick,
      onCalculateShippingCost,
      setSelfPickupAddress,
      modalAlertError,
      modalMessage,
    };
  },
  validations() {
    const transaction = this.transaction;
    const isSelfPickup = this.isSelfPickup;

    return {
      transaction: {
        buyerInfo: {
          userInfo: {
            firstname: { required, alpha },
            lastname: { required, alpha },
            email: { email },
            telephone: { required, minLength: minLength(10) },
          },
          shippingAddress: {
            firstname: {},
            lastname: {},
            address: {
              required: requiredIf(function () {
                return !isSelfPickup;
              }),
              address,
            },
            district: {
              required: requiredIf(function () {
                return !isSelfPickup;
              }),
              alpha,
            },
            subdistrict: {
              required: requiredIf(function () {
                return !isSelfPickup;
              }),
              alpha,
            },
            province: {
              required: requiredIf(function () {
                return !isSelfPickup;
              }),
              alpha,
            },
            zipcode: {
              required: requiredIf(function () {
                return !isSelfPickup;
              }),

              minValue: minValue(5),
              numeric,
            },
          },

          billingAddress: {
            nameTitleId: {
              required: requiredIf(function () {
                return transaction.buyerInfo.isRequestTax;
              }),
              valueDefault(value: number) {
                return valueDefault(
                  value,
                  transaction.buyerInfo.isRequestTax
                );
              },
            },

            firstname: {
              // required: requiredIf(function () {
              //   return transaction.buyerInfo.isRequestTax;
              // }),
              required,
              alpha,
            },
            lastname: {
              // required: requiredIf(function () {
              //   return (
              //     transaction.buyerInfo.isRequestTax
              //     // && transaction.buyerInfo.billingAddress.nameTitleId !== 4
              //   );
              // }),
              required,
              alpha,
            },

            address: {
              required: requiredIf(function () {
                return transaction.buyerInfo.sameAddress !== true;
              }),
              address,
            },
            district: {
              required: requiredIf(function () {
                return transaction.buyerInfo.sameAddress !== true;
              }),
              alpha,
            },
            subdistrict: {
              required: requiredIf(function () {
                return transaction.buyerInfo.sameAddress !== true;
              }),
              alpha,
            },
            province: {
              required: requiredIf(function () {
                return transaction.buyerInfo.sameAddress !== true;
              }),
              alpha,
            },
            zipcode: {
              required: requiredIf(function () {
                return transaction.buyerInfo.sameAddress !== true;
              }),
              minValue: minValue(5),
              numeric,
            },
            tax: {
              required: requiredIf(function () {
                return (
                  transaction.buyerInfo.isRequestTax == true
                );
              }),
              minLength: minLength(13),
              numeric,
            },
          },
        },
      },
    };
  },
});
